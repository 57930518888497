import { format } from 'date-fns/esm'
import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { useHighlightedCard } from '../../app/hooks/useHighlightedCard'
import { Divider } from './RedUI'
import { useUneeqState } from 'uneeq-react-core'

interface LabResultProps {
  labResult: PendingLabResultType
}

interface PendingLabResultType {
  lab_test: {
    _id: string
    title: string
    description: string
    description_html: string
    shortDescription: string
    contentType: string
    tag: string
  }
}

const PendingLabResult = ({ labResult, index }: LabResultProps) => {
  const { linesToSpeak } = useUneeqState()
  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const labResultDate = labResult.date_of_test
    ? format(
        new Date(new Date(labResult.date_of_test).toISOString().slice(0, -1)),
        'EEEE, MMMM d'
      )
    : labResult.complete_within
    ? `Within ${labResult.complete_within} days`
    : ''

  const isHiglighted =
    !currentLine || !currentLine.eachIndex
      ? true
      : index === currentLine?.itemIndex

  return (
    <Flex variant={isHiglighted ? 'card' : 'fadedCard'}>
      <Box>
        <Heading>{labResult.lab_test.title}</Heading>
      </Box>
      <Divider />
      <Box>
        <Text
          variant="contentSmall"
          sx={{ pb: [6, 6, 6, 6, 12, 12] }}
          dangerouslySetInnerHTML={{
            __html: labResult.lab_test.description_html
          }}
        />
        {/* We need to check how's going to be received */}
        {labResultDate && (
          <>
            <Divider />
            <Box sx={{ pt: [6, 6, 6, 6, 12, 12] }}>
              <Text variant="label">Test date:</Text>
              <Text variant="content">{labResultDate}</Text>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  )
}

export default PendingLabResult
