import { format } from 'date-fns'
import { getCalendarHighlightColors } from '../../../theme'
import { useTheme } from 'emotion-theming'

import React from 'react'
import { Card } from 'rebass'
import Calendar, {
  CalendarAppointment,
  CalendarDay,
  getCalendarDates
} from '../RedUI/Calendar'
import { useAppointmentsForCalendar } from './useAppointmentsForCalendar'
import { formatCalendarAppointmentDisplayName } from './utils'

const dateFormat = 'yyyy-MM-dd'

export const DischargeCalendar = ({
  dischargeDate
}: {
  dischargeDate: Date
}) => {
  const calendarDates = getCalendarDates(dischargeDate)
  const appointmentsForCalendar = useAppointmentsForCalendar(dateFormat)

  const theme = useTheme()

  const highlightColors = theme?.colors ? getCalendarHighlightColors(theme) : []

  const labTestColor = theme?.colors?.greyLight

  return Object.values(calendarDates).map((month, monthIndex) => {
    return (
      <Card
        sx={{
          p: [0, 0, 0, 0, 0, 0],
          flexShrink: 0,
          overflow: 'hidden',
          backgroundColor: 'greyLight'
        }}
      >
        <Calendar>
          {month.map(date => (
            <CalendarDay
              date={date}
              monthIndex={monthIndex}
              dischargeDate={dischargeDate}
            >
              {appointmentsForCalendar.map(
                ({ date: dateWithAppts, appointments }, index) =>
                  format(date, dateFormat) === dateWithAppts &&
                  // Highlight the appt just in the corresponding month its being rendered
                  // not in the subsequent month
                  // i.e. 31st of a month will can be represented twice depending the range of days - we just want appt to be shown in its corresponding month
                  format(date, 'yyyy-MM') ==
                    Object.keys(calendarDates)[monthIndex]
                    ? appointments.map(appt => (
                        <CalendarAppointment
                          highlight={
                            appt.date_of_test
                              ? labTestColor
                              : highlightColors[index]
                          }
                          information={formatCalendarAppointmentDisplayName(
                            appt
                          )}
                        />
                      ))
                    : null
              )}
            </CalendarDay>
          ))}
        </Calendar>
      </Card>
    )
  })
}
