import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUneeqState } from 'uneeq-react-core'
import { useSessionContext } from '../../app/hooks/useSessionContext'
import { AfterHospitalCarePlan } from './AfterHospitalCarePlan/AfterHospitalCarePlan'
import { AppointmentsLoader, UnscheduledAppointment } from './Appointments'
import CompletedLabResult from './CompletedLabResult'
import { DischargeCalendar } from './DischargeCalendar'
import ExerciseAndDiet from './ExerciseAndDiet'
import MedicalEquipment from './MedicalEquipment'
import { MedicationsTable } from './Medications'
import PendingLabResult from './PendingLabResult'
import ReccomendationList from './ReccomendationList'
import { HeroLayout } from './RedUI'
import SymptomsList from './SymptomsList'
import { UnscheduledAppointmentsLoader } from './Appointments/UnscheduledAppointmensLoader'

const DischargeUISelector = () => {
  const { mayaQuestion } = useUneeqState()
  const mayaQuestionId = mayaQuestion?.id
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { t } = useTranslation()
  const {
    medications,
    firstInstanceMedications,
    secondInstanceMedications,
    unscheduled_appointments,
    exercise_education,
    diet_education,
    medical_equipments,
    symptoms_list,
    completed_lab_tests,
    upcoming_lab_tests,
    discharge_date,
    after_hospital_care_plan_label
  } = dischargeCarePlan

  // if the appointment doesn't contain the date_of_appointment
  // means that is an unscheduled appointment

  const dischargeDate = new Date(
    new Date(discharge_date).toISOString().slice(0, -1)
  )

  switch (mayaQuestionId) {
    case 'question#24036': // first question
    case 'question#24037':
    case 'question#24041':
    case 'question#24040':
    case 'question#24044': // primary diagnosis
    case 'question#24263': // specific diagnosis
    case 'question#24347': // my caregiver is not available to listen
    case 'question#240263':
    case 'question#24149': // what to do if problem intro
    case 'question#24151': // what to do if problem: section AHCP intro
    case 'question#24152': // what to do if problem: office phone
    case 'question#24153': // what to do if problem: OK call feeling worse
    case 'question#24154': // what to do if problem: call if other symptoms
    case 'question#24155': // what to do if problem: questions?
    case 'question#24156': // what to do if problem: question? OK, noted. let's go on
    case 'question#24230': // finishing up
    case 'question#24265': // finishing up
      return (
        <HeroLayout title={after_hospital_care_plan_label || t('AHCP.title')}>
          <AfterHospitalCarePlan />
        </HeroLayout>
      )

    case 'question#24043':
      return (
        <HeroLayout title={after_hospital_care_plan_label || t('AHCP.title')}>
          <AfterHospitalCarePlan />
        </HeroLayout>
      )
    case 'question#24111': // medication intro
    case 'question#24116': // medication follow along with AHCP
    case 'question#24119': // fix medication issue?
    case 'question#24120': // medication issue list
    case 'question#24121': // medication issue will be fixed
    case 'question#24266': // medication quiz intro
    case 'question#24267': // medication quiz
    case 'question#24269': // medication quiz wrong question
    case 'question#24270': // medication quiz outro
      return (
        <HeroLayout
          title={t('AHCP.section.medication')}
          wrap="cardSecondary"
          blurTitle={t('AHCP.section.medicationBlur')}
          blur={mayaQuestionId === 'question#24267'}
        >
          <MedicationsTable medicationList={medications} />
        </HeroLayout>
      )
    case 'question#24117': // changed medication each
      return (
        <HeroLayout
          title={t('AHCP.section.medication')}
          wrap="cardSecondary"
          blurTitle={t('AHCP.section.medicationBlur')}
          blur={mayaQuestionId === 'question#24267'}
        >
          <MedicationsTable medicationList={firstInstanceMedications} />
        </HeroLayout>
      )
    case 'question#24118': // unchanged medication each
      return (
        <HeroLayout
          title={t('AHCP.section.unchagedMedicines')}
          wrap="cardSecondary"
        >
          <MedicationsTable medicationList={secondInstanceMedications} />
        </HeroLayout>
      )
    case 'question#24352': // medical equipment intro
    case 'question#24351': // medical equipment
    case 'question#24353': // medical equipment talk to nurse?
    case 'question#24354': // medical equipment talk to nurse outro
      return medical_equipments.length > 0 ? (
        <HeroLayout title={t('AHCP.section.medicalEquipment')}>
          {medical_equipments.map((medicalEquipment, index) => (
            <MedicalEquipment
              medicalEquipment={medicalEquipment}
              index={index}
            />
          ))}
        </HeroLayout>
      ) : (
        <div />
      )
    case 'question#23727': // upcoming appointments intro
    case 'question#24053': // upcoming appointments each
    case 'question#24055': // upcoming appointments quiz intro
    case 'question#23735': // upcoming appointments issue
    case 'question#23736': // upcoming appointments issue will be fixed
    case 'question#24056': // upcoming appointments quiz
    case 'question#24058': // upcoming appointments quiz outro
    case 'question#24060': // upcoming appointments quiz wrong question
    case 'question#24059': // upcoming appointments quiz try another question
    case 'question#24057': // upcoming appointments quiz wrong answer
    case 'question#23734':
      return (
        <HeroLayout
          title={t('AHCP.section.upcomingAppt')}
          blurTitle={t('AHCP.section.upcomingApptBlur')}
          blur={
            mayaQuestionId === 'question#24056' ||
            mayaQuestionId === 'question#24059'
          }
        >
          <DischargeCalendar dischargeDate={dischargeDate} />
          <AppointmentsLoader />
        </HeroLayout>
      )
    case 'question#24054':
      return unscheduled_appointments.length > 0 ? (
        <HeroLayout title={t('AHCP.section.unscheduledAppt')}>
          <UnscheduledAppointmentsLoader />
        </HeroLayout>
      ) : (
        <div />
      )
    case 'question#24188': // diet intro
    case 'question#24189': // diet
    case 'question#24190': // talk to nurse about diet?
    case 'question#24271': // talk to nurse about diet outro
      return (
        <HeroLayout title={t('AHCP.section.diet')}>
          {diet_education.map((diet: any, index) => (
            <ExerciseAndDiet item={diet} index={index} />
          ))}
        </HeroLayout>
      )
    case 'question#24192': // exercise intro
    case 'question#24193': // exercise
    case 'question#24194': // talk to nurse about exercise?
    case 'question#24195': // talk to nurse about exercise outro
      return (
        <HeroLayout title={t('AHCP.section.exercise')}>
          {exercise_education.map((exercise: any, index) => (
            <ExerciseAndDiet item={exercise} index={index} />
          ))}
        </HeroLayout>
      )
    case 'question#24196': // upcoming tests intro
    case 'question#24197': // upcoming tests
    case 'question#24198': // talk to nurse about upcoming tests?
    case 'question#24199': // talk to nurse about upcoming tests outro
      return upcoming_lab_tests.length > 0 ? (
        <HeroLayout title={t('AHCP.section.upcomingLabTests')}>
          {upcoming_lab_tests.map((labResult, index) => (
            <PendingLabResult labResult={labResult} index={index} />
          ))}
        </HeroLayout>
      ) : (
        <div />
      )
    case 'question#24200': // pending tests intro
    case 'question#24227': // pending tests
    case 'question#24228': // talk to nurse about pending tests?
    case 'question#24229': // talk to nurse about pending tests outro
      return completed_lab_tests.length > 0 ? (
        <HeroLayout title={t('AHCP.section.pendingLabTests')}>
          {completed_lab_tests.map((labResult, index) => (
            <CompletedLabResult labResult={labResult} />
          ))}
        </HeroLayout>
      ) : (
        <div />
      )

    case 'question#24150': // what to do if problem: symptoms list
      return symptoms_list ? (
        <HeroLayout title={t('AHCP.section.symptoms')} wrap="card">
          <SymptomsList symptomsList={symptoms_list} />
        </HeroLayout>
      ) : (
        <div />
      )
    case 'question#24264': // near end: reminder about upcoming appointments
      return (
        <HeroLayout title={t('AHCP.section.recommendation')} wrap="card">
          <ReccomendationList />
        </HeroLayout>
      )

    default:
      return mayaQuestionId?.includes('question#') ? (
        <HeroLayout title={after_hospital_care_plan_label || t('AHCP.title')}>
          <AfterHospitalCarePlan />
        </HeroLayout>
      ) : null
  }
}

export default DischargeUISelector
