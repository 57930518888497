import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Heading, Image, Text } from 'rebass'
import { useUneeqState } from 'uneeq-react-core'
import { useSessionContext } from '../../../app/hooks/useSessionContext'
import { Divider } from '../RedUI'
import { ComposePhoneNumbers } from './ComposePhoneNumbers'

type DataSection = {
  text: string
  label: string
  highlight?: string
  children: React.ReactNode
}

type DesertSpringsProps = {
  title: string
  children: React.ReactNode
}

const DataSection = ({ highlight, text, label, children }: DataSection) => {
  const { linesToSpeak } = useUneeqState()

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)
  const isHighlighted =
    currentLine?.highlight && highlight && highlight === currentLine?.highlight

  return (
    <Box flex={1} variant={isHighlighted && 'activeSpokenContent'}>
      <Text variant="label">{label}</Text>
      <Flex justifyContent="space-between">
        <Text variant="content" flex={1}>
          {text}
        </Text>
        {children}
      </Flex>
    </Box>
  )
}

export const AfterHospitalCarePlan = () => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { t } = useTranslation()

  const {
    patient,
    discharge_cc,
    phones,
    discharge_date_label,
    medical_conditions,
    discharge_date,
    personal_caregiver_name,
    clinic_specific_text
  } = dischargeCarePlan

  const dischargeDate = format(
    new Date(new Date(discharge_date).toISOString().slice(0, -1)),
    'LLLL do, yyyy'
  )

  return (
    <>
      <Flex variant="ahcpCard" bg="white">
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box>
            <Text variant="label">{t('AHCP.subtitles.patient')}</Text>
            <Heading variant="headingMedium">
              {patient.first_name} {patient.last_name}
            </Heading>
          </Box>

          <Box sx={{ alignItems: 'center' }}>
            <Image
              variant="inlineLogo"
              src={discharge_cc.organization.organizationLogo}
              alt="Logo"
            />
          </Box>
        </Flex>
        <Divider />
        <Flex flexDirection="row" sx={{ gap: [6, 6, 6, 6, 6, 6, 6, 8] }}>
          {personal_caregiver_name && (
            <Flex
              flex={1}
              flexDirection="column"
              sx={{ gap: [6, 6, 6, 6, 6, 6, 6, 8] }}
            >
              <DataSection
                label={
                  discharge_date_label || t('AHCP.subtitles.dischargeDate')
                }
                text={dischargeDate}
              />
              <DataSection
                label={t('AHCP.subtitles.atHomeCaregiver')}
                text={dischargeCarePlan.personal_caregiver_name}
              />
            </Flex>
          )}

          {medical_conditions.map(({ medical_condition }) => (
            <DataSection
              highlight="diagnosis"
              label={t('AHCP.subtitles.primaryDiagnosis')}
              text={medical_condition.name}
            />
          ))}
        </Flex>
      </Flex>

      <Flex variant="ahcpCard" bg="white">
        <Heading variant="headingMedium">Problems and questions</Heading>
        <Divider />

        <Flex flexDirection="column" sx={{ gap: 6 }}>
          {phones.length > 0 &&
            phones.map(
              (
                { name, title, day_contact_number, night_contact_number },
                index
              ) => {
                const composeEmergencyNumbers = []

                if (day_contact_number)
                  composeEmergencyNumbers.push({
                    type: 'day',
                    contact_number: day_contact_number
                  })

                if (night_contact_number)
                  composeEmergencyNumbers.push({
                    type: 'night',
                    contact_number: night_contact_number
                  })

                return (
                  <DataSection
                    highlight={`row_${index}`}
                    label={title}
                    text={name}
                  >
                    <ComposePhoneNumbers numbers={composeEmergencyNumbers} />
                  </DataSection>
                )
              }
            )}
        </Flex>
        <Divider />
        <Heading variant="headingMedium">
          {t('AHCP.subtitles.emergency')}
        </Heading>
        {clinic_specific_text && (
          <Text mb="0" variant="labelMedium" sx={{ textTransform: 'none' }}>
            {clinic_specific_text}
          </Text>
        )}
      </Flex>
    </>
  )
}

const DesertSprings = ({ title = '', children }: DesertSpringsProps) => {
  return (
    <Flex>
      <Text variant="title">{title}</Text>
      <Flex variant="card">{children}</Flex>
    </Flex>
  )
}

export { DesertSprings }
