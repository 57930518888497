const questionResponseMiddleware = (config?: any) => async (
  state: any,
  action: any,
  uneeq: any
) => {
  if (
    (action?.type === 'mayaMessage' && action?.payload?.type === 'response') ||
    (action?.type === 'mayaMessage' && action?.payload?.type === 'exit') ||
    action?.type === 'updateQuestionRepeatCount'
  ) {
    const { dischargeQuestionData } = state

    // Send the captured med data into 'medications' as that how they needed it in the BE
    dischargeQuestionData['medications'] = [
      ...dischargeQuestionData['medications_new_and_changed'],
      ...dischargeQuestionData['medications_stopped_and_unchanged']
    ]

    // Send the captured appt data into 'follow_ups' as that how they needed it in the BE
    dischargeQuestionData['follow_ups'] = [
      ...dischargeQuestionData['scheduled_follow_ups'],
      ...dischargeQuestionData['unscheduled_follow_ups']
    ]

    // Send the captured appt data into 'appointments_upcoming_lab_tests' as that how they needed it in the BE
    dischargeQuestionData['appointments_upcoming_lab_tests'] = [
      ...dischargeQuestionData['scheduled_upcoming_lab_tests'],
      ...dischargeQuestionData['unscheduled_upcoming_lab_tests']
    ]

    const { inferences = [] } = action.payload

    const body = { ...dischargeQuestionData, inferences }

    if (dischargeQuestionData) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/updateCarePlan`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_BACKEND_MAYARED_TOKEN}`
            }
          }
        )
        const bodyRes = await res.json()
        if (bodyRes.error) {
          throw new Error(
            bodyRes.error.response.error.message || res.statusText
          )
        }

        return bodyRes
      } catch (e) {
        throw e
      }
    }
  }
}

export default questionResponseMiddleware
