import React, { useEffect } from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import { UpcomingLabTestType } from '../../../app/types/dischargeCarePlan'
import { Divider } from '../RedUI'
import { useTheme } from 'emotion-theming'

interface LabTestsAppointmentsProps {
  upcomingLabTest: UpcomingLabTestType
  index: number
}

export const LabTestsAppointment = ({
  upcomingLabTest,
  index
}: LabTestsAppointmentsProps) => {
  const { date_of_appointment, lab_test } = upcomingLabTest
  const { cellToHighlight, row } = useHighlightedSection(index)

  const theme = useTheme()

  const labTestColor = theme?.colors?.greyLight

  useEffect(() => {
    if (cellToHighlight === `${row}_column_1`) {
      const apptElement = document.getElementById('appointment-card')
      apptElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [cellToHighlight, row])

  return (
    <Flex
      id="appointment-card"
      variant="appointmentCard"
      sx={{
        background: labTestColor
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Heading variant="headingLarge">
          <Box
            variant={
              cellToHighlight === `${row}_column_3` ? 'activeSpokenContent' : ''
            }
          >
            {date_of_appointment}
          </Box>
        </Heading>
        <Divider />
      </Flex>

      <Box variant="simpleGrid">
        <Box
          variant={
            cellToHighlight === `${row}_column_1` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Lab Test:</Text>
          <Text variant="content">{lab_test?.title}</Text>
        </Box>
      </Box>
    </Flex>
  )
}
