import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'
import { Box, Flex, Image } from 'rebass'
import { Uneeq } from 'uneeq-js'
import {
  UneeqAvatar,
  UneeqContext,
  UneeqLocalVideo,
  UneeqProvider,
  useIsSmallScreen,
  usePortraitScreen,
  useUneeqState
} from 'uneeq-react-core'
import {
  EndSessionConfirm,
  EscalationForm,
  PrivacySummary
} from 'uneeq-react-ui'
import { useSessionContext } from '../../app/hooks/useSessionContext'
import config from '../../config'
import closeSessionMiddleware from '../../customPlugins/CloseSession/closeSessionMiddleware'
import DischargeUISelector from '../../customPlugins/DischargeFlow/DischargeUISelector'
import { loadingGatewayReducer } from '../../customPlugins/LoadingGateway'
import Gateway from '../../customPlugins/LoadingGateway/Gateway'
import {
  MayaBandwidth,
  mayaBandwidthReducer
} from '../../customPlugins/MayaBandwidth'
import mayaConfigReducer from '../../customPlugins/MayaConfig/mayaConfigReducer'
import {
  MayaConfirmLeave,
  mayaConfirmLeaveReducer
} from '../../customPlugins/MayaConfirmLeave'
import {
  MayaConfirmRestart,
  mayaConfirmRestartReducer
} from '../../customPlugins/MayaConfirmRestart'
import {
  MayaDocumentTitle,
  mayaDocumentTitleReducer
} from '../../customPlugins/MayaDocumentTitle'
import mayaErrorsReducer from '../../customPlugins/MayaError/mayaErrorsReducer'
import MayaErrors from '../../customPlugins/MayaErrors/MayaErrors'
import {
  MayaExitSession,
  mayaExitSessionMiddleware,
  mayaExitSessionReducer
} from '../../customPlugins/MayaExitSession'
import MayaInformation from '../../customPlugins/MayaInformation/MayaInformation'
import mayaInformationMiddleware from '../../customPlugins/MayaInformation/mayaInformationMiddleware'
import mayaInformationReducer from '../../customPlugins/MayaInformation/mayaInformationReducer'
import { MayaMap } from '../../customPlugins/MayaMap'
import mayaMapReducer from '../../customPlugins/MayaMap/mayaMapReducer'
import MayaStatement from '../../customPlugins/MayaStatement/MayaStatement'
import mayaStatementReducer from '../../customPlugins/MayaStatement/mayaStatementReducer'
import MayaTimeout from '../../customPlugins/MayaTimeout'
import Transcript from '../../customPlugins/MayaTranscript/Transcript'
import mayaTranscriptReducer from '../../customPlugins/MayaTranscript/mayaTranscriptReducer'
import MayaUi from '../../customPlugins/MayaUi'
import pauseSessionMiddleware from '../../customPlugins/PauseSession/pauseSessionMiddleware'
import pauseSessionReducer from '../../customPlugins/PauseSession/pauseSessionReducer'
import {
  linesToSpeakReducer,
  questionReducer
} from '../../customPlugins/Question'
import Question from '../../customPlugins/Question/Question'
import questionResponseMiddleware from '../../customPlugins/Question/questionResponseMiddleware'
import questionResponseReducer from '../../customPlugins/Question/questionResponseReducer'
import sessionIDMiddleware from '../../customPlugins/SessionID/sessionIDMiddleware'
import sessionIDReducer from '../../customPlugins/SessionID/sessionIDReducer'
import mayaEchoMiddleware from '../../customPlugins/mayaEchoMiddleware'
import { endSession as endMayaSession, getToken } from '../../socket'
import UIToolbar from '../UIToolbar'
import assets from '../assets'
import { useDomainConfigContext } from '../hooks/useDomainConfigContext'
import { useTvContext } from '../hooks/useTvContext'
import { dischargeCarePlanType } from '../types/dischargeCarePlan'
import DeviceOrientationChecker from './DeviceOrientationChecker'

const {
  video: { permissions }
} = assets

// state slice to close all modals
export const closeModals = {
  menuOpen: false,
  settingsOpen: false,
  endConfirmOpen: false,
  timeoutOpen: false,
  confirmLeave: false,
  privacyOpen: false,
  error: null
}

// state slice to close all dialogs
export const closeDialogs = {
  question: null,
  transcriptOpen: false,
  error: null
}
const getReducers = (
  dischargeCarePlan: dischargeCarePlanType,
  session?: string
) => {
  return [
    mayaTranscriptReducer({
      onOpen: { ...closeModals, ...closeDialogs }
    }),
    mayaErrorsReducer,
    mayaMapReducer(),
    pauseSessionReducer(),
    questionReducer(dischargeCarePlan),
    questionResponseReducer(dischargeCarePlan),
    mayaStatementReducer(),
    linesToSpeakReducer(),
    mayaBandwidthReducer(config.maxLoadingTimeInMs),
    loadingGatewayReducer(),
    mayaConfigReducer(),
    mayaInformationReducer(),
    mayaConfirmLeaveReducer,
    mayaConfirmRestartReducer,
    mayaDocumentTitleReducer(config.defaultDocumentTitle),
    mayaExitSessionReducer(),
    sessionIDReducer(session)
  ]
}

const debugMiddleware = () => (state: any, action: any) => {
  console.info(action)
  return state
}

const middleware: any = [
  debugMiddleware(),
  mayaInformationMiddleware(),
  closeSessionMiddleware({ endMayaSession }),
  pauseSessionMiddleware(),
  sessionIDMiddleware(),
  questionResponseMiddleware(),
  mayaExitSessionMiddleware({ endMayaSession })
]

interface DigitalHumanContentProps {
  restart: () => void
  speak?: boolean
  widgetMode: boolean
  fullscreen?: boolean
  embeddedMode: boolean
  hideDownloadNotes?: boolean
  patientSelectedId?: string
  loadingMode?: string
  disableLandscapeMode?: boolean
  digitalHumanOnly?: boolean
}

const getRootNode = (): HTMLElement => {
  const rootNode = document.getElementById('mayaChatWidget')

  if (rootNode?.shadowRoot) {
    const shadowDomRoot = rootNode.shadowRoot.getElementById('react-root')
    return shadowDomRoot ? shadowDomRoot : document.body
  }

  return rootNode ? rootNode : document.body
}

const GatewayChildren = ({ finalRestart, widgetMode, speak }: any) => {
  const rootNode = getRootNode()
  const isSmallScreen = useIsSmallScreen()
  return (
    <>
      <UIToolbar />

      {/* Modals */}

      {ReactDOM.createPortal(
        <MayaConfirmLeave onConfirm={finalRestart} />,
        rootNode
      )}
      {ReactDOM.createPortal(<MayaConfirmRestart speak={speak} />, rootNode)}
      {!widgetMode && <MayaTimeout endSession={finalRestart} />}
      <PrivacySummary />
      {ReactDOM.createPortal(
        <EndSessionConfirm restart={finalRestart} />,
        rootNode
      )}
      {!isSmallScreen &&
        ReactDOM.createPortal(
          <ReactTooltip className="react-tooltip-zindex" />,
          rootNode
        )}
      <EscalationForm restart={finalRestart} />
      <MayaExitSession />
    </>
  )
}

const DigitalHumanContent = ({
  speak,
  widgetMode,
  fullscreen = false,
  embeddedMode,
  hideDownloadNotes,
  patientSelectedId,
  preAuthToken,
  loadingMode,
  disableLandscapeMode,
  digitalHumanOnly
}: DigitalHumanContentProps) => {
  const { dispatch } = useContext(UneeqContext)
  const { tvAppMode } = useTvContext()
  const portraitScreen = usePortraitScreen()
  const {
    sessionPaused,
    transcriptOpen,
    loadingVideoActive,
    mayaQuestion
  } = useUneeqState()
  const {
    state: { showLogoOnFlow, logo }
  } = useDomainConfigContext()
  const isSmallScreen = useIsSmallScreen()
  useEffect(() => {
    dispatch({ type: 'setSpeak', payload: speak })
  }, [speak, dispatch])
  const { restartSession } = useContext(UneeqContext)

  const finalRestart = () => {
    endMayaSession()
    restartSession()
  }

  return (
    <>
      {showLogoOnFlow && (
        <Box variant="homeLogo">
          <Image src={logo} alt="Logo"></Image>
        </Box>
      )}

      <DeviceOrientationChecker disableLandscapeMode={disableLandscapeMode}>
        <Flex
          sx={{
            label: 'dhContainer',
            position: 'absolute',
            height: ['100%', '100%', '100%', '100%', '100%', '100%'],
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '100%',
            ...(widgetMode
              ? {
                  position: 'static',
                  overflow: 'hidden',
                  justifyContent: 'flex-end'
                }
              : {})
          }}
        >
          {!widgetMode && (
            <UneeqAvatar
              sx={{
                height: 'auto',
                marginLeft: sessionPaused ? '-5000000px' : '0px' // TODO: change for MayaRed
              }}
              shouldUseDynamicWidth={
                isSmallScreen || (embeddedMode && portraitScreen)
              }
            />
          )}
          <MayaUi speak={speak} />
          <MayaMap />
          <Flex
            variant="interactionContainer"
            sx={{
              ...(transcriptOpen ? { height: '100%' } : {}),
              ...(tvAppMode ? { justifyContent: 'flex-start' } : {}),
              ...(!speak || sessionPaused
                ? {
                    transform: [
                      'translateX(0)',
                      'translateX(0)',
                      'translateX(0)',
                      'translateX(0)',
                      'translateX(-50%)',
                      'translateX(-50%)'
                    ],
                    height: '90%',
                    left: ['unset', 'unset', 'unset', 'unset', '50%', '50%']
                  }
                : {
                    left: ['auto', 'auto', 'auto', 'auto', 0, 0]
                  }),
              ...(widgetMode
                ? {
                    position: 'static',
                    transform: 'translateX(0)',
                    p: 0,
                    overflow: 'hidden',
                    height: fullscreen
                      ? '100%'
                      : ['75vh', '75vh', '75vh', '75vh', '85vh', '85vh']
                  }
                : {})
            }}
          >
            {tvAppMode && (
              <>
                <MayaBandwidth />
                {!loadingVideoActive && (
                  <>
                    <Question speak={speak} />
                    <MayaInformation hideDownloadNotes={hideDownloadNotes} />
                  </>
                )}
                <Transcript />
              </>
            )}
            {widgetMode && (
              <UneeqAvatar
                sx={{
                  label: 'UneeqAvatar',
                  height: 'auto',
                  flex: 1,
                  display: transcriptOpen ? 'none' : 'flex',
                  marginLeft: sessionPaused ? '-5000000px' : 0,
                  borderRadius: transcriptOpen ? 'unset' : 'card',
                  position: transcriptOpen ? 'absolute' : 'initial'
                }}
                shouldUseDynamicWidth
              />
            )}
            {!tvAppMode && (
              <>
                <MayaBandwidth digitalHumanOnly={digitalHumanOnly} />
                <Transcript />
                {!loadingVideoActive && (
                  <>
                    <DischargeUISelector />
                    <Flex variant="questionsArea">
                      <Question speak={speak} />
                    </Flex>

                    <MayaInformation hideDownloadNotes={hideDownloadNotes} />
                  </>
                )}
              </>
            )}

            {widgetMode && (
              <Gateway
                showLoadingVideo={Boolean(speak)}
                restart={finalRestart}
                video={permissions}
                widgetMode
              >
                <GatewayChildren
                  widgetMode={widgetMode}
                  finalRestart={finalRestart}
                />
              </Gateway>
            )}
          </Flex>
          <MayaStatement />
          {/* Must be present but we want it hidden */}
          <UneeqLocalVideo style={{ display: 'none' }} />

          {ReactDOM.createPortal(<MayaErrors />, getRootNode())}
          {!widgetMode && (
            <Gateway
              showLoadingVideo={Boolean(speak)}
              restart={finalRestart}
              video={permissions}
              loadingMode={loadingMode}
            >
              <GatewayChildren
                speak={Boolean(speak)}
                widgetMode={widgetMode}
                finalRestart={finalRestart}
              />
            </Gateway>
          )}
        </Flex>
      </DeviceOrientationChecker>
    </>
  )
}

const DigitalHuman = ({
  postInit,
  speak,
  onTimedOut,
  onSessionEnded,
  restart,
  widgetMode = false,
  fullscreen,
  setFullscreen,
  embeddedMode,
  personaId,
  testTimeoutTime,
  hideTranscript,
  hideDownloadNotes,
  uneeqURL,
  session,
  patientSelectedId,
  preAuthToken,
  loadingMode,
  disableLandscapeMode,
  digitalHumanOnly
}: DigitalHumanProps) => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()

  return (
    <UneeqProvider
      config={{
        ...config,
        url: uneeqURL ? uneeqURL : config.url,
        conversationId: personaId ? personaId : config.conversationId,
        timeout: testTimeoutTime ? testTimeoutTime : config.timeout,
        timeoutWarning: testTimeoutTime
          ? testTimeoutTime - 1000
          : config.timeoutWarning,
        hideTranscript: Boolean(hideTranscript)
      }}
      reducers={getReducers(dischargeCarePlan, session)}
      speak={speak}
      middleware={[...middleware, mayaEchoMiddleware(dischargeCarePlan)]}
      getToken={getToken}
      postInit={postInit}
      restart={restart}
      onTimedOut={onTimedOut}
      onSessionEnded={onSessionEnded}
      fullscreen={fullscreen}
      setFullscreen={setFullscreen}
      sessionId={session}
    >
      <DigitalHumanContent
        speak={speak}
        restart={restart}
        widgetMode={widgetMode}
        fullscreen={fullscreen}
        embeddedMode={embeddedMode}
        hideDownloadNotes={hideDownloadNotes}
        patientSelectedId={patientSelectedId}
        preAuthToken={preAuthToken}
        loadingMode={loadingMode}
        disableLandscapeMode={disableLandscapeMode}
        digitalHumanOnly={digitalHumanOnly}
      />
    </UneeqProvider>
  )
}

interface DigitalHumanProps {
  onTimedOut: () => void
  onSessionEnded: () => void
  postInit?: (uneeq: Uneeq) => void
  token?: string
  speak?: boolean
  restart: () => void
  widgetMode?: boolean
  fullscreen?: boolean
  setFullscreen?: Dispatch<SetStateAction<boolean>>
  embeddedMode: boolean
  personaId: string
  testTimeoutTime?: number
  hideTranscript?: boolean
  hideDownloadNotes?: boolean
  uneeqURL?: string
  session?: string
  patientSelectedId?: string
  preAuthToken?: string
  loadingMode?: string
  disableLandscapeMode?: boolean
  digitalHumanOnly?: boolean
}

export default DigitalHuman
