import { add, format } from 'date-fns'
import { dischargeCarePlanType } from '../../app/types/dischargeCarePlan'

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min)
}

const questionReducer = (
  dischargeCarePlan: dischargeCarePlanType,
  config?: any
) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'question':
          // replace quiz options
          const nextAppointment = dischargeCarePlan.quiz_data.nextAppointment

          const nextAppointmentDate =
            nextAppointment && new Date(nextAppointment?.date_raw)

          const incorrectAppointment1String =
            nextAppointmentDate &&
            `${format(
              add(nextAppointmentDate, { days: 3 }),
              'EEEE, MMMM d'
            )} at 10:00 am`

          const incorrectAppointment2String =
            nextAppointmentDate &&
            `${format(
              add(nextAppointmentDate, { days: 4 }),
              'EEEE, MMMM d'
            )} at 8:00 am`

          const dosageCorrectMedicationUnit =
            dischargeCarePlan.quiz_data.randomCorrectMedication?.form
              .category !== 'Other'
              ? dischargeCarePlan.quiz_data.randomCorrectMedication
                  ?.dosage_strength_unit.title
              : dischargeCarePlan.quiz_data.randomCorrectMedication
                  ?.dosage_unit ??
                dischargeCarePlan.quiz_data.randomCorrectMedication?.dosage_unit
                  ?.title

          const dosageCorrectMedication =
            dischargeCarePlan.quiz_data.randomCorrectMedication?.form
              .category !== 'Other'
              ? `${dischargeCarePlan.quiz_data.randomCorrectMedication?.dosage_strength} ${dosageCorrectMedicationUnit}`
              : `${dischargeCarePlan.quiz_data.randomCorrectMedication?.dosage_multiplier} ${dosageCorrectMedicationUnit}`

          // invent one departing from the correct one
          const dosageIncorrectMedication1 = `${parseInt(
            dosageCorrectMedication
          ) + getRandomNumber(1, 5)} ${dosageCorrectMedicationUnit}`
          const dosageIncorrectMedication2 = `${parseInt(
            dosageCorrectMedication
          ) + getRandomNumber(7, 10)} ${dosageCorrectMedicationUnit}`

          const optionsMap = {
            '61441': dosageIncorrectMedication1, // question#24267 (Medicines) Incorrect dosage 1
            '61442': dosageCorrectMedication, // question#24267 (Medicines) Correct %dosage_am%
            '61443': dosageIncorrectMedication2, // question#24267 (Medicines) Incorrect dosage 2
            '60978': incorrectAppointment1String, // question#24056 (Appointments) %Incorrect_Date_1%
            '60979': dischargeCarePlan.quiz_data.nextAppointmentDateString, // question#24056 (Appointments) %Correct_Date%
            '60980': incorrectAppointment2String // question#24056 (Appointments) %Incorrect_Date_2%
          }

          if (action.payload?.options?.length > 0) {
            action.payload.options = action.payload.options.map((opt: any) => {
              if (optionsMap[opt?.id]) {
                return { ...opt, label: optionsMap[opt?.id] }
              } else {
                return opt
              }
            })
          }

          return {
            ...state,
            linesStartedSpeaking: false,
            mayaQuestion: action.payload,
            mayaInformation: null,
            questionLoading: false,
            autocompleteOptions: [],
            hideQuestionTitle:
              state.speak &&
              !state.sessionPaused &&
              (state.mayaConfig?.hideQuestion || action.payload?.hideQuestion)
          }
        case 'autocomplete':
          return { ...state, autocompleteOptions: action.payload.matches }
        case 'response':
          return {
            ...state,
            talkNextItem: false,
            talkPreviousItem: false,
            questionLoading: true,
            autocompleteOptions: []
          }
        default:
          return state
      }
    case 'clearQuestionLoading':
      return {
        ...state,
        questionLoading: false
      }
    case 'pauseSession':
      return {
        ...state,
        hideQuestionTitle: false
      }
    case 'resumeSession':
      return {
        ...state,
        hideQuestionTitle:
          state.speak &&
          (state.mayaConfig?.hideQuestion || state.mayaQuestion?.hideQuestion)
      }
    case 'linesToSpeakSet':
      console.log('LINESTOSPEAK QUESTION. Payload:', action.payload)
      return {
        ...state,
        linesToSpeak: action.payload.linesToSpeak,
        stringToSpeak: action.payload.stringToSpeak
      }
    case 'linesStartedSpeaking':
      return {
        ...state,
        linesStartedSpeaking: action.payload
      }
    default:
      return state
  }
}

export default questionReducer
