import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from 'rebass'
import { format } from 'date-fns'
import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import {
  formatArrayNameIntoOneSentence,
  stringSentenceAnidate
} from '../../../utils'
import { MedCell } from './MedicationsTable'

import { ReactComponent as EveningIcon } from '../../../app/assets/icons/evening.svg'
import { ReactComponent as MorningIcon } from '../../../app/assets/icons/morning.svg'
import { ReactComponent as NightIcon } from '../../../app/assets/icons/night.svg'
import { ReactComponent as NoonIcon } from '../../../app/assets/icons/noon.svg'
interface MedicationTimeIconProps {
  medicineTime: string
  width?: string
  height?: string
}

const MedicationTimeIcon = ({
  medicineTime,
  width = '18px',
  height = '18px'
}: MedicationTimeIconProps) => {
  switch (medicineTime) {
    case 'morning':
      return <MorningIcon width={width} height={height} />
    case 'noon':
      return <NoonIcon width={width} height={height} />
    case 'evening':
      return <EveningIcon width={width} height={height} />
    case 'night':
      return <NightIcon width={width} height={height} />
    default:
      return <div />
  }
}

interface MedicationTimesProps {
  medicineSchedule: Array<string>
  timings?: Array<string>
  weekDay?: Array<string>
}

const MedicationTimes = ({
  medicineSchedule,
  timings,
  weekDay
}: MedicationTimesProps) => {
  const { t } = useTranslation()

  return (
    <Flex sx={{ flexDirection: 'column', gap: 1, alignItems: 'start' }}>
      <Flex variant="medicationTime" flexDirection="column" alignItems="start">
        {weekDay?.length > 0 ? (
          <Text textAlign="start">
            <Flex sx={{ flexDirection: 'row', gap: 1 }}>
              <MedicationTimeIcon medicineTime="morning" />
              <Text>Every </Text>
            </Flex>
            {timings?.length > 0 && (
              <Text>{stringSentenceAnidate(timings)}</Text>
            )}
            <Text>{stringSentenceAnidate(weekDay)}</Text>
          </Text>
        ) : (
          medicineSchedule.map(medicineTime => (
            <Flex sx={{ flexDirection: 'row', gap: 1 }}>
              <MedicationTimeIcon medicineTime={medicineTime} />
              <Text>{t(`Medication.MedicationTimes.${medicineTime}`)}</Text>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  )
}

export interface MedicationType {
  medicineName: string
  genericName: string
  reason: string
  dosage_multiplier?: string
  dosage_unit: string
  dosage_strength?: number
  next_dose?: boolean
  form: {
    _id: string
    title: string
    category: string
    shortTitle?: string
  }
  strength?: number
  strength_unit?: {
    _id: string
    title: string
  }
  dosage_strength_unit?: {
    _id: string
    title: string
  }
  solvent?: number
  dosage_solvent?: number
  dosage_solvent_unit?: {
    _id: string
    title: string
  }
  solvent_unit?: {
    _id: string
    title: string
  }
  timesPerDay: number
  oldDosage: { value: string }
  status: string // TODO: change to boolean
  medicationRoute: string
  additional_directions?: string
  is_stopped: boolean
  next_dosage_date?: string
  next_dosage_timings?: string
  show_actual_next_dosage_timings?: boolean
  next_dosage_medication_schedule?: string
}

interface MedicationProps {
  medication: MedicationType
  index: number
}

export const MedicationRow = ({ medication, index }: MedicationProps) => {
  const { t } = useTranslation()
  const { isHighlightedSection, cellToHighlight, row } = useHighlightedSection(
    index
  )

  const reasonsToTakeMed = medication.reason_to_take_medicine.length
    ? formatArrayNameIntoOneSentence(medication.reason_to_take_medicine)
    : `-`

  const formNaming = medication.form
    ? `${
        medication.solvent
          ? `/ ${medication.solvent} ${medication.solvent_unit?.title}`
          : ''
      } ${medication.form.shortTitle || medication.form.title}`
    : ''

  return (
    <Flex
      variant="medicationsTableCard"
      style={{
        opacity: isHighlightedSection ? 1 : 0.4
      }}
    >
      <Flex variant="medicationsTableRow" id={row}>
        <MedCell
          sx={{ flexBasis: '10%' }}
          activeSpokenContent={cellToHighlight === `${row}_column_1`}
        >
          <Text variant="contentMicro" sx={{ fontWeight: 'bold' }}>
            {medication.genericName && `${medication.genericName}`}
            {medication.medicineName && ` / ${medication.medicineName}`}{' '}
            {medication.dosage?.value}
          </Text>{' '}
          <Flex flexDirection="row" sx={{ marginTop: '8px' }}>
            {medication.is_new_medicine && <Flex variant="pillNew">New</Flex>}
            {medication.is_change_in_dosage_or_frequency && (
              <Flex variant="pillChanged">Changed</Flex>
            )}
            {medication.is_stopped && (
              <Flex variant="pillStopped">{t('AHCP.medications.stopped')}</Flex>
            )}
            {medication?.dosage_strength && (
              <Text
                variant="contentXSmall"
                sx={{ color: 'GrayText', fontWeight: 'bold' }}
              >
                {medication.strength} {medication.strength_unit?.title}{' '}
                {formNaming}
              </Text>
            )}
          </Flex>
        </MedCell>
        <MedCell activeSpokenContent={cellToHighlight === `${row}_column_2`}>
          <Text variant="contentXSmall">{reasonsToTakeMed}</Text>
        </MedCell>
        <MedCell activeSpokenContent={cellToHighlight === `${row}_column_3`}>
          {medication?.dosage_strength || medication.dosage_multiplier ? (
            medication?.form.category === 'Application' ||
            medication?.form.category === 'Other' ? (
              <Text variant="contentXSmall">
                {medication.dosage_multiplier}{' '}
                {medication.dosage_unit ??
                  medication.dosage_solvent_unit?.title}
              </Text>
            ) : (
              <>
                {medication?.dosage_strength && (
                  <Text variant="contentXSmall">
                    {medication?.dosage_strength}{' '}
                    {medication?.dosage_strength_unit?.title}
                  </Text>
                )}
                {medication.dosage_multiplier && (
                  <Text variant="contentXSmall">
                    ({medication.dosage_multiplier}{' '}
                    {medication.dosage_solvent_unit?.title ??
                      medication.dosage_unit}
                    )
                  </Text>
                )}
              </>
            )
          ) : // Only show the 'See additional directions' for new or changed medicines
          medication.is_change_in_dosage_or_frequency ||
            medication.is_new_medicine ? (
            <Text variant="contentXSmall">See Additional Directions</Text>
          ) : (
            '-'
          )}
        </MedCell>
        <MedCell activeSpokenContent={cellToHighlight === `${row}_column_4`}>
          <Text variant="contentXSmall">{medication.how_to_take ?? `-`}</Text>
        </MedCell>
        <MedCell activeSpokenContent={cellToHighlight === `${row}_column_5`}>
          <Text variant="contentXSmall">
            {medication.medicineSchedule?.length > 0 ? (
              <MedicationTimes
                medicineSchedule={medication.medicineSchedule}
                weekDay={medication.weekDay ?? null}
                timings={medication.timings ?? null}
              />
            ) : (
              `-`
            )}
          </Text>
        </MedCell>

        <MedCell activeSpokenContent={cellToHighlight === `${row}_column_6`}>
          <Text variant="contentXSmall">
            {medication.duration || medication.duration_unit
              ? `${medication.duration ?? ''} ${medication.duration_unit ?? ''}`
              : `-`}
          </Text>
        </MedCell>
      </Flex>
      {(medication.additional_directions || medication.next_dosage_date) && (
        <Flex flexDirection="column" sx={{ gap: 1 }}>
          {medication.additional_directions && (
            <Text variant="contentXSmall">
              <b>Additional Directions: </b>
              {medication.additional_directions}
            </Text>
          )}
          {medication.next_dosage_date && (
            <Text
              variant="contentXSmall"
              sx={{
                textTransform: medication.show_actual_next_dosage_timings
                  ? 'initial'
                  : 'capitalize'
              }}
            >
              <Flex sx={{ gap: 1 }}>
                <b>Next Dose: </b>
                {medication.next_dosage_date} -
                <MedicationTimeIcon
                  medicineTime={medication.next_dosage_medication_schedule}
                />
                {medication.show_actual_next_dosage_timings
                  ? medication.next_dosage_timings
                  : medication.next_dosage_medication_schedule}
              </Flex>
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  )
}
