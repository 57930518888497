const QUESTION_STATUS = {
  SKIPPED: 'Skipped',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In progress'
}

const getBaseItem = (item: any, subItemName?: string) => {
  let returnItem = {
    _id: item._id,
    dh_status: null,
    repeated_times: 0,
    title: item.title
  }
  if (subItemName && item[subItemName]) {
    returnItem[subItemName] = { _id: item[subItemName]._id }
  }
  return returnItem
}

export const getInitialDischargeQuestionData = (
  dischargeCarePlan: any,
  responsePayload: any
) => {
  const { id, options, question } = responsePayload
  const {
    completed_lab_tests,
    educations,
    upcoming_lab_tests,
    medical_equipments,
    diet_education,
    exercise_education,
    medical_conditions,
    medical_conditions_other,
    firstInstanceMedications,
    secondInstanceMedications,
    scheduled_upcoming_lab_tests,
    unscheduled_upcoming_lab_tests,
    scheduled_follow_ups,
    unscheduled_follow_ups,
    phones
  } = dischargeCarePlan

  const initialData = {
    _id: dischargeCarePlan._id,
    dh_status: QUESTION_STATUS.IN_PROGRESS,
    resetPlan: false,
    medications_new_and_changed: firstInstanceMedications.map(
      (medication: any) => getBaseItem(medication)
    ),
    medications_stopped_and_unchanged: secondInstanceMedications.map(
      (medication: any) => getBaseItem(medication)
    ),
    completed_lab_tests: completed_lab_tests.map((labTest: any) =>
      getBaseItem(labTest)
    ),
    scheduled_follow_ups: scheduled_follow_ups.map((followUp: any) =>
      getBaseItem(followUp)
    ),
    unscheduled_follow_ups: unscheduled_follow_ups.map((followUp: any) =>
      getBaseItem(followUp)
    ),
    scheduled_upcoming_lab_tests: scheduled_upcoming_lab_tests.map(
      (followUp: any) => getBaseItem(followUp, 'lab_test')
    ),
    unscheduled_upcoming_lab_tests: unscheduled_upcoming_lab_tests.map(
      (followUp: any) => getBaseItem(followUp, 'lab_test')
    ),
    educations: educations.map((education: any) => getBaseItem(education)),
    medical_conditions: medical_conditions.map((medical_condition: any) =>
      getBaseItem(medical_condition, 'medical_condition')
    ),
    medical_conditions_other: medical_conditions_other.map(
      (medical_condition: any) =>
        getBaseItem(medical_condition, 'medical_condition')
    ),
    upcoming_lab_tests: upcoming_lab_tests.map((upcomingLabTest: any) =>
      getBaseItem(upcomingLabTest, '')
    ),
    medical_equipments: medical_equipments.map((medicalEquipment: any) =>
      getBaseItem(medicalEquipment)
    ),
    exercise_education: exercise_education.map((exerciseEducation: any) =>
      getBaseItem(exerciseEducation)
    ),
    diet_education: diet_education.map((dietEducation: any) =>
      getBaseItem(dietEducation)
    ),
    phones: phones.map((phone: any) => getBaseItem(phone)),
    questionsAnswers: [
      {
        questionId: id,
        questionText: question,
        repeatedTimes: 0,
        responseId: options[0].id,
        responseText: options[0].label,
        isQuiz: false
      }
    ]
  }
  return initialData
}

// Medications and appointments quiz
export const quizQuestions = {
  'question#24267': {
    '61442': 'Correct',
    '61441': 'Incorrect',
    '61443': 'Incorrect',
    '61448': 'Incorrect'
  },
  'question#24056': {
    '60979': 'Correct',
    '60978': 'Incorrect',
    '60980': 'Incorrect'
  }
}

export const responsesWithNurseActionRequired = [
  '61452', // Caregiver not available to listen
  '61108', // I don't take one of the medicines
  '61109', // One of my doses is incorrect
  '61110', // I noticed there is a medicine missing
  '61111', // Something else,
  '61449', // Incorrect answer - Medicines
  '60303', // I don't know why I have one of my appointments
  '60304', // An appointment is with the wrong doctor
  '60305', // I won't be able to go to one of my appointments
  '60306', // I need to be sure I can get a ride
  '60307', // Something else
  '60981', // Incorrect answer - Appointments
  '61191', // Yes, I have something I'd like to ask
  '61270', // Yes, please refer me to a nutritionist or dietician
  '61276', // Yes, I'd like to be referred to a physical therapist
  '61282', // Yes, that would be helpful - Would you like to talk with a nurse about your upcoming tests?
  '61348', // Yes - Would you like to talk with the nurse about your pending tests?
  '61628' // Yes - Would you like to tal…ut the medical equipment you'll be using at home?
]

export const questionIdsToSections = {
  'question#24111': {
    label: ['medications_new_and_changed', 'medications_stopped_and_unchanged'],
    '61091': QUESTION_STATUS.IN_PROGRESS,
    '61092': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  },
  'question#24116': {
    label: ['medications_new_and_changed'],
    '61102': QUESTION_STATUS.IN_PROGRESS,
    countRepeatedTimes: false
  },
  'question#24117': {
    label: ['medications_new_and_changed'],
    '61103': QUESTION_STATUS.COMPLETED,
    '61104': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true,
    optionalChange: {
      label: 'medications_stopped_and_unchanged',
      '61103': QUESTION_STATUS.IN_PROGRESS,
      '61104': QUESTION_STATUS.SKIPPED
    }
  },
  'question#24118': {
    label: ['medications_stopped_and_unchanged'],
    '61105': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24352': {
    label: ['medical_equipments'],
    '61626': QUESTION_STATUS.IN_PROGRESS,
    '61627': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  },
  'question#24351': {
    label: ['medical_equipments'], // When repeats A
    '61625': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#23727': {
    label: ['scheduled_follow_ups', 'scheduled_upcoming_lab_tests'],
    '60289': QUESTION_STATUS.IN_PROGRESS,
    '60290': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false,
    optionalChange: {
      label: ['unscheduled_follow_ups', 'unscheduled_upcoming_lab_tests'],
      '60289': null,
      '60290': QUESTION_STATUS.SKIPPED
    }
  },
  'question#24053': {
    label: ['scheduled_follow_ups', 'scheduled_upcoming_lab_tests'], // When repeats A
    '60974': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true,
    optionalChange: {
      label: ['unscheduled_follow_ups', 'unscheduled_upcoming_lab_tests'],
      '60974': QUESTION_STATUS.IN_PROGRESS
    }
  },
  'question#24054': {
    label: ['unscheduled_follow_ups', 'unscheduled_upcoming_lab_tests'], // When repeats A
    '60975': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24188': {
    label: ['diet_education'],
    '61267': QUESTION_STATUS.IN_PROGRESS,
    '61268': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  },
  'question#24189': {
    label: ['diet_education'], // When repeats A
    '61269': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24192': {
    label: ['exercise_education'],
    '61273': QUESTION_STATUS.IN_PROGRESS,
    '61274': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  },
  'question#24193': {
    label: ['exercise_education'], // When repeats A
    '61275': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  // In one Q we recognise if the section has been skipped or completed
  // but on the A is where we should check if that section is repeated
  // So if the Q or the A is repeated, for now it will count equally
  'question#24196': {
    label: ['upcoming_lab_tests'], // When repeats Q
    '61279': QUESTION_STATUS.IN_PROGRESS,
    '61280': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  },
  'question#24197': {
    label: ['upcoming_lab_tests'], // When repeats A
    '61281': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24200': {
    label: ['completed_lab_tests'],
    '61285': QUESTION_STATUS.IN_PROGRESS,
    '61286': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false
  }, // pending lab tests
  'question#24227': {
    label: ['completed_lab_tests'],
    '61347': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24043': {
    label: ['medical_conditions', 'medical_conditions_other'],
    '60953': QUESTION_STATUS.SKIPPED,
    '60952': QUESTION_STATUS.IN_PROGRESS,
    countRepeatedTimes: false
  },
  'question#24044': {
    label: ['medical_conditions'], // When repeats A
    '60954': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24149': {
    label: ['phones'],
    '61184': QUESTION_STATUS.IN_PROGRESS,
    '61185': QUESTION_STATUS.SKIPPED,
    countRepeatedTimes: false,
    optionalChange: {
      label: 'educations',
      '61184': null,
      '61185': QUESTION_STATUS.SKIPPED,
      shouldUpdate: (carePlanData: any, itemToUpdate: any) =>
        'Symptom List' === itemToUpdate?.title
    }
  },
  'question#24371': {
    label: ['phones'],
    '61662': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  },
  'question#24150': {
    label: ['educations'],
    '61186': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true,
    shouldUpdate: (carePlanData: any, itemToUpdate: any) =>
      'Symptom List' === itemToUpdate?.title
  },
  'question#24230': {
    label: 'dh_status',
    '61351': QUESTION_STATUS.COMPLETED
  },
  'question#24263': {
    label: ['medical_conditions_other'],
    '61435': QUESTION_STATUS.COMPLETED,
    countRepeatedTimes: true
  }
}
