import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { Divider } from './RedUI'
import { useUneeqState } from 'uneeq-react-core'

interface ExerciseAndDietProps {
  item: ExerciseAndDietType
}

interface ExerciseAndDietType {
  title: string
  shortDescription: string
  description: string
  tag: string
}

const ExerciseAndDiet = ({ item, index }: ExerciseAndDietProps) => {
  const { title, description_html } = item
  const { linesToSpeak } = useUneeqState()
  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const isHighlighted =
    !currentLine || currentLine?.eachIndex === null
      ? true
      : index === currentLine?.itemIndex

  return (
    <Flex variant={isHighlighted ? 'card' : 'fadedCard'}>
      <Box>
        <Heading>{title}</Heading>
      </Box>
      <Divider />
      <Text
        variant="contentSmall"
        sx={{ pb: [6, 6, 6, 6, 12, 12] }}
        dangerouslySetInnerHTML={{ __html: description_html }}
      />
    </Flex>
  )
}

export default ExerciseAndDiet
