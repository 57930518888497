import { useUneeqState } from 'uneeq-react-core'
import { useDomainConfigContext } from './useDomainConfigContext'
import { useIsMayaSpeakingOrLoadingNewLines } from './useIsMayaSpeakingOrLoadingNewLines'

export const useShouldShowButtons = () => {
  const isMayaSpeaking = useIsMayaSpeakingOrLoadingNewLines()
  const { linesStartedSpeaking } = useUneeqState()

  const {
    state: { hideButtonsWhenDHSpeaks, alwaysShowButtons }
  } = useDomainConfigContext()

  const shouldShowButtons =
    alwaysShowButtons ||
    (hideButtonsWhenDHSpeaks && linesStartedSpeaking && !isMayaSpeaking) ||
    !hideButtonsWhenDHSpeaks

  return shouldShowButtons
}
