const getQuizMedication = medications => {
  // We want to make sure to use a medication that has dosage_strength
  const filterMedications = medications.filter(med => med?.dosage_strength)

  return filterMedications[Math.floor(Math.random() * filterMedications.length)]
}

const getNextAppointment = allAppointments => {
  const appointments = [...allAppointments].sort((a, b) => {
    return new Date(a.date_raw) - new Date(b.date_raw)
  })

  const nextAppointment = appointments[0] ?? null

  return nextAppointment
}

export const quizGenerator = (medications, appointments) => {
  // Quiz generate correct medication
  const randomCorrectQuizMedication = getQuizMedication(medications)

  // Quiz generate first incorrect medication
  const randomIncorrectQuizMedication1 = getQuizMedication(
    medications.filter(rnd =>
      rnd.dosage?.value
        ? rnd?.dosage_strength !== randomCorrectQuizMedication?.dosage_strength
        : false
    )
  )

  // Quiz generate second incorrect medication
  const randomIncorrectQuizMedication2 = getQuizMedication(
    medications.filter(rnd =>
      rnd.dosage?.value
        ? rnd?.dosage_strength !==
            randomCorrectQuizMedication?.dosage_strength &&
          rnd?.dosage_strength !==
            randomIncorrectQuizMedication1?.dosage_strength
        : false
    )
  )

  const quizFollowUp = getNextAppointment(appointments)

  return {
    nextAppointment: quizFollowUp,
    nextAppointmentDateString: quizFollowUp
      ? `${quizFollowUp?.date_of_appointment}
      ${
        quizFollowUp?.time_of_appointment
          ? ` at ${quizFollowUp.time_of_appointment}`
          : ''
      }
    `
      : ``,
    randomCorrectMedication: randomCorrectQuizMedication,
    randomIncorrectMedication1: randomIncorrectQuizMedication1,
    randomIncorrectMedication2: randomIncorrectQuizMedication2
  }
}
