import { format } from 'date-fns'
import { formatArrayNameIntoOneSentence, formatOrdinals } from '../utils'
import {
  getDosageInformation,
  getMedicationTimes,
  getMedicationTimesWithTiming
} from './DischargeFlow/Medications/utils'

export const coMorbiditiesFormatList = coMorbidities =>
  coMorbidities.reduce((formattedList, coMorbidity, index) => {
    const finalString =
      coMorbidities.length === 1
        ? coMorbidity.medical_condition?.name || coMorbidity.condition_other
        : coMorbidities.length - 2 === index
        ? `${formattedList} ${coMorbidity.medical_condition?.name ||
            coMorbidity.condition_other}`
        : coMorbidities.length - 1 === index
        ? `${formattedList} and ${coMorbidity.medical_condition?.name ||
            coMorbidity.condition_other}`
        : `${formattedList} ${coMorbidity.medical_condition?.name ||
            coMorbidity.condition_other},`

    return finalString.trim()
  }, '')

export const testsFormatItem = item => {
  const { lab_test, complete_within, date_of_test } = item

  //It should always have complete_within or date_of_test but the API is not consistent 🤡
  const dateOfLab = complete_within
    ? `within ${complete_within} days`
    : date_of_test
    ? `on ${format(
        new Date(new Date(date_of_test).toISOString().slice(0, -1)),
        'EEEE, MMMM d'
      )}`
    : ''

  return {
    title: `${lab_test?.title}${lab_test.description && ', '}`,
    '#education: lab tests#': lab_test.description
      ? `${lab_test.description}`
      : '',
    education_lab_test: lab_test.description ? `${lab_test.description}` : '',
    date_of_lab: dateOfLab
  }
}

export const dietFormatItem = item => {
  const { title, description } = item

  return {
    diet: title ?? '',
    education_diet: description ?? ''
  }
}

export const exerciseFormatItem = item => {
  const { title, description } = item

  return {
    exercise_plan: title ?? '',
    education_exercise: description ?? ''
  }
}

export const medicalEquipmentFormatItem = (
  item,
  textToBeCheckedWithItemInformation
) => {
  const { date_of_medical_equipment, equipment } = item
  const { title, description } = equipment

  const ISODate = date_of_medical_equipment
    ? format(
        new Date(
          new Date(date_of_medical_equipment).toISOString().slice(0, -1)
        ),
        'EEEE, MMMM d'
      )
    : ''

  let extraReplacementsMedEquip = {}

  if (textToBeCheckedWithItemInformation) {
    const replacedText = !date_of_medical_equipment
      ? textToBeCheckedWithItemInformation.filter(text =>
          text.match(/(#date_of medical_equipment#)(.*?)/gs)
        )
      : []

    extraReplacementsMedEquip = replacedText.reduce((a, value) => {
      // Get only the part that's after the ## so it can be replaced with empty string
      const [stringLine] = value.match(/[\r\n]+([^\r\n]+)/gs)
      // Remove the \n that were used to match the string that's needed to be replaced
      const lineToReplace = stringLine.replace(/\r?\n|\r/g, '')

      return {
        ...a,
        [lineToReplace]: ''
      }
    }, {})
  }

  return {
    medical_equipment: title,
    'education: medical_equipment': description,
    date_of_medical_equipment: `${ISODate}.`,
    extraReplacements: {
      '#date_of medical_equipment#': '',
      ...extraReplacementsMedEquip
    }
  }
}

export const apptFormatItem = item => {
  const dateToBeRead = item?.date_of_appointment
    ? `${item?.date_of_appointment}${
        item?.time_of_appointment ? ` at ${item.time_of_appointment}` : ''
      }`
    : null // We can ask them how they want the the date to be read

  // Include full stop to the note if it doesn't have it
  const note = item?.note
    ? `${item.note}${item.note.slice(-1) === '.' ? '' : '.'}`
    : ''

  return {
    reason: item?.speciality
      ? item.speciality.name
      : item?.speciality_other || (item?.lab_test.title ?? null),
    name: item?.doctor
      ? `${item.doctor.Fname ? item.doctor.Fname : ''} ${
          item.doctor.Lname ? item.doctor.Lname : ''
        }`
      : item.doctor_other ?? '',
    date: item?.date_of_appointment ? dateToBeRead : null,
    time: item?.time_of_appointment,
    note: note,
    address: item?.address ?? '',
    timing: item?.complete_within ?? '',
    contact_no: item?.contact_number,
    // it's being received different in new and unscheduled appointments
    number_appointment: formatOrdinals(item?.index),
    appointment_number: formatOrdinals(item?.index)
  }
}

export const medicationFormatItem = (
  medicine,
  textToBeCheckedWithItemInformation
) => {
  const {
    medicineName,
    genericName,
    reason_to_take_medicine,
    duration,
    duration_unit,
    how_to_take,
    how_often,
    medicineSchedule,
    weekDay,
    timings,
    description
  } = medicine

  let extraReplacementsMedicine = {}

  // TODO extract this to a function so it can be used with the rest of formats
  if (textToBeCheckedWithItemInformation) {
    const replacedText = medicine.is_new_medicine
      ? textToBeCheckedWithItemInformation.filter(
          text => !text.match(/(#new_medications#)(.*?)/gs)
        )
      : medicine.is_change_in_dosage_or_frequency
      ? textToBeCheckedWithItemInformation.filter(
          text => !text.match(/(#changed_medications#)(.*?)/gs)
        )
      : medicine.is_stopped
      ? textToBeCheckedWithItemInformation.filter(
          text => !text.match(/(#stopped_medications#)(.*?)/gs)
        )
      : textToBeCheckedWithItemInformation.filter(
          text => !text.match(/(#unchanged_medications#)(.*?)/gs)
        )

    extraReplacementsMedicine = replacedText.reduce((a, value) => {
      // Get only the part that's after the ## so it can be replaced with empty string
      const [stringLine] = value.match(/[\r\n]+([^\r\n]+)/gs)
      // Remove the \n that were used to match the string that's needed to be replaced
      const lineToReplace = stringLine.replace(/\r?\n|\r/g, '')

      return {
        ...a,
        [lineToReplace]: ''
      }
    }, {})
  }

  const take = `it ${how_to_take}`
  const when =
    weekDay?.length > 0
      ? getMedicationTimesWithTiming(timings, weekDay)
      : `${how_often}, ${
          medicineSchedule ? getMedicationTimes(medicineSchedule) : ''
        }`

  const stop_in =
    duration_unit === 'Ongoing' || !duration_unit
      ? `when your doctor tells you`
      : `${duration && `in ${duration}`} ${duration_unit ?? ''}`

  const nextDose = medicine.next_dosage_date
    ? `${medicine.next_dosage_date} ${
        medicine.show_actual_next_dosage_timings
          ? `at ${medicine.next_dosage_timings}`
          : getMedicationTimes([medicine.next_dosage_medication_schedule])
      }`
    : ''

  return {
    // WEAK for preventing bad formatting on the tokenization
    // TODO check a better solution, integral for when there are numbers ending with a dot
    medicineName: `${medicineName} `,
    genericName:
      genericName?.length !== 0
        ? genericName ?? medicineName
        : medicineName ?? '',
    reason: reason_to_take_medicine.length
      ? `${formatArrayNameIntoOneSentence(reason_to_take_medicine)}`
      : '',
    take,
    when,
    stop_in,
    next_dose: nextDose,
    dosage_information: getDosageInformation(medicine),
    extraReplacements: {
      '#new_medications#': '',
      '#changed_medications#': '',
      '#stopped_medications#': '',
      '#unchanged_medications#': '',
      ...extraReplacementsMedicine
    },
    description: description
      ? `${description} ${description.slice(-1) !== '.' ? '.' : ''}`
      : `You can talk to your doctor if you'd like to learn more about this medicine.`
  }
}

export const phonesFormatItems = phone => {
  return {
    title: phone?.title,
    day_contact_number: phone?.name
  }
}

// EXAMPLE

// --TITLE: Pending Tests--
// #pending_test#

// #if 1#
// The test that your doctors need to check is %title%.
// #end-if 1#

// #if more than 1#
// The tests that your doctors need to check are:
// --start-each--
// %title%
// --end-each--
// #end-if more than 1#

export const parseIfandEachMatches = (text, itemsAmount) => {
  // Since there're cases that don't have the IF but does have the EACH
  // First we filter with that match

  // For the cases with just EACH we divide the text into 3
  // beforeEach
  // multipleItemsInsideEach
  // afterEach

  // For the cases with IF and EACH, the 3 parts will be built as the following
  // IF: Two cases -> single or multiple items to be read
  // beforeEach = the text contained before the IF and the text depending which case of the IF is needed
  // textInsideEach = For single item or multiple items
  // textAfterEach = same as just the each

  let textBeforeEach = null,
    textInsideEach = null,
    textAfterEach = null

  const eachIterableText = text?.match(
    /(.*)(?:--start-each--)(.*)(?:--end-each--)(.*)/s
  )

  if (itemsAmount && eachIterableText) {
    const [, beforeEach, multipleItemsInsideEach, afterEach] = eachIterableText
    const ifIterableText = beforeEach?.match(
      /(.*)(?:#if 1#)(.*)(?:#end-if 1#)(.*)/s
    )

    if (!ifIterableText) {
      textBeforeEach = beforeEach
      textInsideEach = multipleItemsInsideEach
      textAfterEach = afterEach
    } else {
      const [
        ,
        beforeIf,
        singleItemInsideEach,
        multipleItemsBeforeEach
      ] = ifIterableText

      textBeforeEach = `${beforeIf} ${
        itemsAmount > 1 ? multipleItemsBeforeEach : ''
      }`
      textInsideEach =
        itemsAmount > 1 ? multipleItemsInsideEach : singleItemInsideEach
      textAfterEach = afterEach
    }
  }

  return {
    textBeforeEach,
    textInsideEach,
    textAfterEach
  }
}

export const isWhitespaceString = (str: string) =>
  !str.replace(/\s/g, '').length
