import { useUneeqState } from 'uneeq-react-core'

// Maybe better to just have it inside src/customPlugins/DischargeFlow ?
export const useHighlightedSection = (index: number) => {
  const { linesToSpeak } = useUneeqState()

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const isHighlightedSection = currentLine
    ? currentLine.eachIndex !== null
      ? currentLine.eachIndex === index
      : true
    : true

  const cellToHighlight = currentLine?.highlight ?? null

  return {
    isHighlightedSection,
    cellToHighlight,
    row: `row_${index}`
  }
}
