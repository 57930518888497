import { useSessionContext } from '../../../app/hooks/useSessionContext'
import React from 'react'
import {
  UnscheduledAppointment,
  UnscheduledLabTestsAppointment
} from './UnscheduledAppointment'

export const UnscheduledAppointmentsLoader = () => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { unscheduled_appointments } = dischargeCarePlan

  return (
    <>
      {unscheduled_appointments.map((appointment: any, index: number) =>
        appointment.lab_test ? (
          <UnscheduledLabTestsAppointment
            upcomingLabTest={appointment}
            index={index}
          />
        ) : (
          <UnscheduledAppointment appointment={appointment} index={index} />
        )
      )}
    </>
  )
}
