import { format } from 'date-fns/esm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Heading, Text } from 'rebass'
import { useHighlightedCard } from '../../app/hooks/useHighlightedCard'
import { Divider } from './RedUI'
import { useUneeqState } from 'uneeq-react-core'

interface MedicalEquipmentProps {
  medicalEquipment: MedicalEquipmentType
  index: number
}

interface MedicalEquipmentType {
  equipment: {
    title: string
    shortDescription: string
    description: string
    description_html: string
    tag: string
  }
}

const MedicalEquipment = ({
  medicalEquipment,
  index
}: MedicalEquipmentProps) => {
  const { t } = useTranslation()
  const { linesToSpeak } = useUneeqState()
  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const { equipment, date_of_medical_equipment } = medicalEquipment

  const isHiglighted =
    !currentLine || !currentLine.eachIndex
      ? true
      : index === currentLine?.itemIndex

  const ISODate = date_of_medical_equipment
    ? format(
        new Date(
          new Date(date_of_medical_equipment).toISOString().slice(0, -1)
        ),
        'EEEE, MMMM d'
      )
    : ''

  return (
    <Flex variant={isHiglighted ? 'card' : 'fadedCard'}>
      <Box>
        <Heading>{equipment.title}</Heading>
      </Box>
      <Divider />
      <Text
        variant="contentSmall"
        sx={{ pb: [6, 6, 6, 6, 12, 12] }}
        dangerouslySetInnerHTML={{ __html: equipment.description_html }}
      />
      {date_of_medical_equipment && (
        <>
          <Divider />
          <Box>
            <Text variant="label">{t('medicalEquipment.dateTitle')}:</Text>
            <Text variant="content">{ISODate}</Text>
          </Box>
        </>
      )}
    </Flex>
  )
}

export default MedicalEquipment
