import React from 'react'
import { Flex, Text } from 'rebass'
import { ReactComponent as MoonSVG } from '../../../app/assets/icons/moon.svg'
import { ReactComponent as SunSVG } from '../../../app/assets/icons/sun.svg'
import phoneFormatter from 'phone-number-formats'

export const LoadPhoneIcon = ({ type }) => {
  switch (type) {
    case 'day':
      return <SunSVG />
    case 'night':
      return <MoonSVG />

    default:
      return (
        <>
          <SunSVG />
          <Text variant="contentMedium">/</Text>
          <MoonSVG />
        </>
      )
  }
}

export const PhoneAndIcon = ({ type, number }) => {
  const formattedNumber =
    number &&
    new phoneFormatter(number)
      .format({ type: 'international' })
      .toString()
      .replace('(', '')
      .replace(')', '')
      .replace('-', ' ')
  return (
    <Flex alignItems="center" sx={{ gap: 3 }}>
      <LoadPhoneIcon type={type} />
      {formattedNumber && (
        <Text variant="contentSmall" sx={{ whiteSpace: 'nowrap' }}>
          {formattedNumber}
        </Text>
      )}
    </Flex>
  )
}

export const ComposePhoneNumbers = ({ numbers }) => {
  const singleContactNumber =
    numbers.length > 1 &&
    numbers?.reduce((prev, current) => {
      if (prev === current.contact_number) {
        return current.contact_number
      }
      return null
    }, numbers[0]?.contact_number)

  return (
    <Flex alignItems="center" sx={{ gap: 2 }}>
      {singleContactNumber ? (
        <PhoneAndIcon number={singleContactNumber} />
      ) : (
        numbers?.map(({ type, contact_number }, index) => (
          <Flex index={index} sx={{ gap: 2 }}>
            {index > 0 && <Text variant="contentMedium"> / </Text>}
            <PhoneAndIcon type={type} number={contact_number} />
          </Flex>
        ))
      )}
    </Flex>
  )
}
