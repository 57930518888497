import { i18n } from 'uneeq-react-ui'
import { stringSentenceAnidate } from '../../../utils'

export const getMedicationTimes = schedule => {
  const prefixedTimes = []

  schedule &&
    schedule.forEach(key => {
      prefixedTimes.push(i18n.t(`Medication.MedicationTimes.prefixed.${key}`))
    })

  const last = prefixedTimes?.pop()
  const medicationTimes =
    schedule.length === 1
      ? last
      : `${prefixedTimes.join(', ')} ${i18n.t(
          `Medication.MedicationTimes.syntax.and`
        )} ${last}`

  return medicationTimes
}

export const getMedicationTimesWithTiming = (
  timings: Array<string>,
  weekDay: Array<string>
) => {
  return `${i18n.t(
    `Medication.MedicationTimes.syntax.every`
  )} ${timings?.length > 0 &&
    stringSentenceAnidate(timings)} ${weekDay?.length > 0 &&
    stringSentenceAnidate(weekDay)}`
}

export const getDosageInformation = medicineDosage => {
  const {
    form,
    solvent,
    dosage_solvent,
    dosage_unit,
    solvent_unit,
    dosage_strength,
    dosage_strength_unit,
    dosage_multiplier,
    additional_directions
  } = medicineDosage

  let dosageInformation = ''

  if (dosage_strength || dosage_multiplier) {
    switch (form.category) {
      case 'Standard':
        dosageInformation = `You will take ${dosage_strength} ${dosage_strength_unit?.title ||
          dosage_unit}${
          dosage_multiplier
            ? ` or ${dosage_multiplier} ${dosage_unit}(s).`
            : '.'
        }`
        break
      case 'Solution':
        dosageInformation = `You will take ${dosage_strength} ${
          dosage_strength_unit?.title
        }${
          dosage_solvent ? ` or ${dosage_solvent} ${solvent_unit?.title}.` : '.'
        }`
        break
      case 'Application':
        dosageInformation = `You will apply this medicine ${dosage_multiplier} time${
          parseInt(dosage_multiplier) > 1 ? `s.` : '.'
        }`
        break
      case 'Other':
        dosageInformation = `You will take ${dosage_multiplier} ${dosage_unit}${
          parseInt(dosage_multiplier) > 1 ? `s.` : '.'
        }`
        break
      default:
        dosageInformation = ''
        break
    }
  }

  // Include the additional directions sentence with the dosage information
  // Sometimes they send the additional directions with a final stop and sometimes they don't
  // I've included a check so we normalize the data and Maya reads it always ok
  if (additional_directions) {
    dosageInformation += `${
      dosageInformation.length > 0 ? ' ' : ''
    }Your doctor has given additional directions, they are: 
    ${additional_directions}${additional_directions.endsWith('.') ? '' : '.'}`
  }

  return dosageInformation
}
