import { format } from 'date-fns'
import { useSessionContext } from '../../../app/hooks/useSessionContext'

export const useAppointmentsForCalendar = (
  dateFormat?: string = 'yyyy-MM-d'
) => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { scheduled_appointments } = dischargeCarePlan

  return scheduled_appointments.reduce((prevArray, appt, index) => {
    const { date_raw } = appt
    const ISODate = format(
      new Date(new Date(date_raw).toISOString().slice(0, -1)),
      dateFormat
    )

    const found = prevArray.find(
      separatedByDate => separatedByDate.date === ISODate
    )

    return found
      ? prevArray.map(prev =>
          prev.date === ISODate
            ? { ...prev, appointments: [...prev.appointments, appt] }
            : prev
        )
      : [...prevArray, { date: ISODate, appointments: [appt] }]
  }, [])
}
