import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'

import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import { AppointmentType } from '../../../app/types/dischargeCarePlan'
import { Divider } from '../RedUI'
import { useTranslation } from 'react-i18next'

interface UnscheduledAppointmentProps {
  appointment: AppointmentType
  index: number
}

export const UnscheduledAppointment = ({
  appointment,
  index
}: UnscheduledAppointmentProps) => {
  const { t } = useTranslation()
  const { doctor, speciality } = appointment
  const { cellToHighlight, row } = useHighlightedSection(index)

  // If the name of speciality is empty, we need to check over speciality_other
  // if it is there
  const appointmentReason = speciality
    ? speciality.name
    : appointment.speciality_other || null

  return (
    <Flex variant="card">
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        {!!appointment.contact_number && (
          <>
            <Box
              variant={
                cellToHighlight === `${row}_column_5`
                  ? 'activeSpokenContent'
                  : ''
              }
            >
              <Text variant="label">Call for an appointment to:</Text>
              <Heading>{appointment.contact_number}</Heading>
            </Box>
            <Divider />
          </>
        )}
      </Flex>

      {appointment.note && (
        <Box
          variant={
            cellToHighlight === `${row}_column_3` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="contentSmall">
            <b>{t('AHCP.appointments.notes')}: </b>
            {appointment.note}
          </Text>
        </Box>
      )}

      <Box variant="simpleGrid">
        <Box
          variant={
            cellToHighlight === `${row}_column_2` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Doctor:</Text>
          <Text variant="content">
            {doctor.Fname} {doctor.Lname}
          </Text>
        </Box>
        <Box>
          <Text variant="label">Where:</Text>
          <Text variant="content">{appointment.address}</Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_1` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Reason:</Text>
          <Text variant="content">{appointmentReason}</Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_${appointment.note ? '4' : '3'}`
              ? 'activeSpokenContent'
              : ''
          }
        >
          {(!!appointment.email || !!appointment.contact_number) && (
            <Text variant="label">Contact the office:</Text>
          )}
          {!!appointment.email && (
            <Text variant="content">Email {appointment.email}</Text>
          )}
          {!!appointment.contact_number && (
            <Text variant="content">Call {appointment.contact_number}</Text>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export const UnscheduledLabTestsAppointment = ({ upcomingLabTest, index }) => {
  const { lab_test, complete_within } = upcomingLabTest
  const { cellToHighlight, row } = useHighlightedSection(index)

  return (
    <Flex variant="card">
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Box
          variant={
            cellToHighlight === `${row}_column_1` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Make an appointment for:</Text>
          <Heading>{lab_test?.title}</Heading>
        </Box>
      </Flex>
      {complete_within && (
        <>
          <Divider />
          <Box variant="simpleGrid">
            <Box
              variant={
                cellToHighlight === `${row}_column_4`
                  ? 'activeSpokenContent'
                  : ''
              }
            >
              <Text variant="label">Complete within:</Text>
              <Text variant="content">{`${complete_within} days`}</Text>
            </Box>
          </Box>
        </>
      )}
    </Flex>
  )
}
