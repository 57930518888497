export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  console.log('b64Data', b64Data)
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const downloadBase64 = (b64Data: string, contentType: string) => {
  const blob = b64toBlob(b64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)

  window.open(blobUrl, '_blank')
}

export const fnOnEnter = (fn: Function) => (
  e: React.KeyboardEvent<HTMLElement>
) => {
  if (e.code === 'Enter') {
    fn(e)
  }
}

export const removeHTMLTags = (str: string) =>
  String(str).replace(/(<([^>]+)>)/gi, '')

export const decodeHTMLEntities = (html: string) => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const formatArrayNameIntoOneSentence = (array: []) => {
  return array.reduce((formattedList, object, index) => {
    const finalString =
      array.length === 1
        ? object.name
        : array.length - 2 === index
        ? `${formattedList} ${object.name}`
        : array.length - 1 === index
        ? `${formattedList} and ${object.name}`
        : `${formattedList} ${object.name},`

    return finalString.trim()
  }, '')
}

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th']
])

export const stringSentenceAnidate = (arrayOfStrings: Array<string>) =>
  // For preventing a bug on the BE
  typeof arrayOfStrings === 'string'
    ? arrayOfStrings
    : arrayOfStrings?.reduce((formattedString, str, index) => {
        const finalString =
          arrayOfStrings.length === 1
            ? str
            : arrayOfStrings.length - 2 === index
            ? `${formattedString} ${str}`
            : arrayOfStrings.length - 1 === index
            ? `${formattedString} and ${str}`
            : `${formattedString} ${str},`

        return finalString.trim()
      }, '')

export const formatOrdinals = n => {
  // @todo: if necessary, we can pass the language selected
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' })
  const rule = pr.select(n)
  const suffix = suffixes.get(rule)
  return `${n}${suffix}`
}
