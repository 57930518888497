import React from 'react'
import { Flex, Heading, Image, Text } from 'rebass'
import { useUneeqState } from 'uneeq-react-core'
import { useTranslation } from 'react-i18next'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import { MayaOverlay } from '../MayaOverlay'
import styles from './styles'
import PoweredByLogo from '../../../src/app/assets/img/powered-by-maya.png'
import { useSessionContext } from '../../app/hooks/useSessionContext'

const MayaExitSession = () => {
  const { exitSession } = useUneeqState()
  const { widgetMode } = useWidgetContext()
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { t } = useTranslation()
  const { discharge_cc } = dischargeCarePlan

  if (!exitSession || widgetMode) return null

  return (
    <MayaOverlay>
      <Flex variant="modal">
        <Image
          variant="inlineLogo"
          src={discharge_cc?.organization?.organizationLogo}
          alt="Logo"
          sx={{ marginBottom: '1em' }}
        />
        <Text sx={{ fontWeight: 600, fontSize: '32px' }}>
          The session has ended
        </Text>
        <Text sx={{ fontWeight: 'normal', fontSize: '30px' }}>Thank you</Text>
        <Image
          src={PoweredByLogo}
          alt="Powered by MayaMD"
          sx={{ marginTop: '1em' }}
        />
      </Flex>
    </MayaOverlay>
  )
}

export default MayaExitSession
