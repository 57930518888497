import { useTheme } from 'emotion-theming'
import React, { useEffect, useState } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import { getCalendarHighlightColors } from '../../../theme'
import { useSessionContext } from '../../../app/hooks/useSessionContext'

import { Appointment } from './Appointment'
import { LabTestsAppointment } from './LabTestsAppointment'

const appointmentsSeparatedByDate = appointments =>
  appointments.reduce((prevArray, appt, index) => {
    const { date_raw } = appt

    const found = prevArray.find(
      separatedByDate => separatedByDate.date === date_raw
    )

    return found
      ? prevArray.map(prev =>
          prev.date === date_raw
            ? { ...prev, appointments: [...prev.appointments, appt] }
            : prev
        )
      : [...prevArray, { date: date_raw, appointments: [appt] }]
  }, [])

export const AppointmentsLoader = () => {
  const { linesToSpeak } = useUneeqState()
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { scheduled_appointments: appointments } = dischargeCarePlan

  const [apptToShowIndex, setApptToShowIndex] = useState<number>(0)
  console.log('linesToSpeak', linesToSpeak)
  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const theme = useTheme()

  const highlightColors = theme?.colors ? getCalendarHighlightColors(theme) : []

  const colorIndex = appointmentsSeparatedByDate(appointments).findIndex(
    ({ date }, index) => date === appointments[apptToShowIndex].date_raw
  )

  useEffect(() => {
    if (currentLine) {
      setApptToShowIndex(currentLine.eachIndex || 0)
    }
  }, [appointments, currentLine])

  if (appointments.length === 0) return null

  const apptToShow = appointments[apptToShowIndex]

  return apptToShow.date_of_test ? (
    <LabTestsAppointment upcomingLabTest={apptToShow} index={apptToShowIndex} />
  ) : (
    <Appointment
      appointment={apptToShow}
      index={apptToShowIndex}
      apptColour={highlightColors[colorIndex]}
    />
  )
}
