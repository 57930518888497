import { dischargeCarePlanType } from '../../app/types/dischargeCarePlan'
import {
  getInitialDischargeQuestionData,
  questionIdsToSections,
  quizQuestions,
  responsesWithNurseActionRequired
} from './questionResponseReducerHelper'

const updateNewQuestionResponse = (
  itemToUpdate,
  questionSection,
  dischargeCarePlan,
  label,
  fnToApply
) =>
  Array.isArray(itemToUpdate)
    ? itemToUpdate.map((item: any) => {
        // if we're inside educations, check if we should update the item first
        if (label === 'educations') {
          const shouldUpdate =
            questionSection.shouldUpdate ??
            questionSection.optionalChange.shouldUpdate

          return shouldUpdate(dischargeCarePlan, item)
            ? fnToApply(item, questionSection.countRepeatedTimes)
            : item
        }

        return fnToApply(item, questionSection.countRepeatedTimes)
      })
    : fnToApply(itemToUpdate, questionSection.countRepeatedTimes)

const getNewQuestionResponse = (
  idToUse: any,
  currentState: any,
  dischargeCarePlan: any,
  newQuestionResponse: any,
  updateToApply: any,
  questionSectionOptional?: any,
  response?: any
) => {
  const questionSection = questionIdsToSections[idToUse]

  if (questionSection) {
    Array.isArray(questionSection.label)
      ? questionSection.label.forEach(label => {
          const itemToUpdate = currentState?.dischargeQuestionData[label]

          newQuestionResponse.dischargeQuestionData[
            label
          ] = updateNewQuestionResponse(
            itemToUpdate,
            questionSection,
            dischargeCarePlan,
            label,
            updateToApply
          )
        })
      : // For the case where the question section to be updated doesn't contain multiple objects
        // we just return the response selected (ex. just returning the dh_status)
        (newQuestionResponse.dischargeQuestionData[questionSection.label] =
          questionSection[response])

    if (questionSection.optionalChange && questionSectionOptional) {
      Array.isArray(questionSection.optionalChange.label)
        ? questionSection.optionalChange.label.forEach(secondaryLabel => {
            const secondaryItemToUpdate =
              currentState?.dischargeQuestionData[secondaryLabel]

            newQuestionResponse.dischargeQuestionData[
              secondaryLabel
            ] = updateNewQuestionResponse(
              secondaryItemToUpdate,
              questionSection,
              dischargeCarePlan,
              secondaryLabel,
              questionSectionOptional
            )
          })
        : (newQuestionResponse.dischargeQuestionData[
            questionSection.optionalChange.label
          ] = updateNewQuestionResponse(
            currentState?.dischargeQuestionData[
              questionSection.optionalChange.label
            ],
            questionSection,
            dischargeCarePlan,
            questionSection.optionalChange.label,
            questionSectionOptional
          ))
    }
  }
  return newQuestionResponse
}

// NOTE: this is how they're taking it from the API - will be better to have consistency
// questionsAnswers -> expects camelCase
// rest dischargeQuestionData -> expects snake_case
const updateQuestionRepeatedTimes = (
  responseInfo: any,
  currentState: any,
  dischargeCarePlan: any,
  isBackButton: boolean
) => {
  const { questionId, id } = responseInfo
  const idToUse = questionId || id // It changes where the id is retrieved, if its a "response" or "question"

  // First update the questionsAnswers array with increasing the question repeated times
  const questionsAnswersUpdated = currentState?.dischargeQuestionData?.questionsAnswers?.map(
    qa =>
      qa.questionId === idToUse
        ? {
            ...qa,
            repeatedTimes: qa.repeatedTimes + 1
          }
        : qa
  )

  let newQuestionResponse = {
    ...currentState,
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // update question answers
      questionsAnswers: questionsAnswersUpdated
    }
  }

  return getNewQuestionResponse(
    idToUse,
    currentState,
    dischargeCarePlan,
    newQuestionResponse,
    (item: any, countRepeatedTimes) => ({
      ...item,
      dh_status:
        // For the case when we are hitting 'back' button,
        countRepeatedTimes
          ? item.dh_status === 'In Progress'
            ? 'Completed'
            : item.dh_status
          : item.dh_status,
      repeated_times: isBackButton
        ? countRepeatedTimes && item.dh_status === 'Completed'
          ? item.repeated_times + 1
          : item.repeated_times
        : countRepeatedTimes
        ? item.repeated_times + 1
        : item.repeated_times
    })
  )
}

const updateQuestionResponse = (
  responseInfo: any,
  currentState: any,
  dischargeCarePlan: any
) => {
  const { questionId, response, label } = responseInfo

  const questionsAnswersUpdated = currentState?.dischargeQuestionData?.questionsAnswers?.map(
    qa =>
      qa.questionId === questionId
        ? {
            ...qa,
            responseId: response,
            responseText: label,
            questionText: currentState.stringToSpeak,
            // We have to let know the nurse know when the patient has answered a wrong question
            // Or particular questions that requires nurse action
            nurseActionRequired: quizQuestions[questionId]
              ? quizQuestions[questionId][response] === 'Incorrect'
              : responsesWithNurseActionRequired.includes(response),
            // Quiz variables
            answeredCorrectly: quizQuestions[questionId]
              ? quizQuestions[questionId][response] === 'Correct'
              : null,
            correctAnswer: label,
            correctAnswerId:
              quizQuestions[questionId] &&
              Object.keys(quizQuestions[questionId]).find(
                (key, index) => quizQuestions[questionId][key] === 'Correct'
              )
          }
        : qa
  )

  let newQuestionResponse = {
    ...currentState,
    // Reset the lines to speak until they are again generated for this upcoming question
    linesToSpeak: [],
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // update question answers
      questionsAnswers: questionsAnswersUpdated
    }
  }

  const questionSection = questionIdsToSections[questionId]

  const questionSectionOptional = questionSection?.optionalChange
    ? (item: any) => ({
        ...item,
        // If the status has already been marked as 'Completed' do not change it
        dh_status:
          item.dh_status === 'Completed'
            ? item.dh_status
            : item.dh_status === 'In Progress'
            ? 'Completed'
            : questionSection.optionalChange[response]
      })
    : () => {}

  return getNewQuestionResponse(
    questionId,
    currentState,
    dischargeCarePlan,
    newQuestionResponse,
    (item: any) => ({
      ...item,
      // If the status has already been marked as 'Completed' do not change it
      dh_status:
        item.dh_status === 'Completed'
          ? item.dh_status
          : item.dh_status === 'In Progress'
          ? 'Completed'
          : questionSection[response]
    }),
    questionSectionOptional,
    response
  )
}

const addQuestionResponse = (
  responseInfo: any,
  currentState: any,
  dischargeCarePlan: any
) => {
  const { id: questionId, question } = responseInfo

  // First we check if the question hasn't been added before
  // If that's the case, we don't want to add it again and we want to increase the repeated times
  // This scenario is reached when we hit "back" button
  const findQuestionAdded = currentState?.dischargeQuestionData?.questionsAnswers?.find(
    qa => qa.questionId === questionId
  )

  if (findQuestionAdded)
    return updateQuestionRepeatedTimes(
      responseInfo,
      currentState,
      dischargeCarePlan,
      true
    )

  const newQuestionAnswer = {
    questionId,
    questionText: question,
    repeatedTimes: 0,
    responseId: null,
    responseText: null,
    quizStatus: quizQuestions[questionId] ? 'In Progress' : null,
    isQuiz: quizQuestions[questionId] ? true : false
    // TODO: if quiz, and incorrect answer, add correct answer (we should send all
    // that from 'response' message probably and get it from responseInfo (the action payload))
  }

  let newQuestionResponse = {
    ...currentState,
    // Reset the lines to speak until they are again generated for this upcoming question
    linesToSpeak: [],
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // add new question answer
      questionsAnswers: [
        ...currentState?.dischargeQuestionData?.questionsAnswers,
        newQuestionAnswer
      ]
    }
  }

  const questionSection = questionIdsToSections[questionId]

  const questionSectionOptional = questionSection?.optionalChange
    ? (item: any, countRepeatedTimes) => ({
        ...item,
        dh_status: item?.dh_status ?? null
      })
    : () => {}

  return getNewQuestionResponse(
    questionId,
    currentState,
    dischargeCarePlan,
    newQuestionResponse,
    // If the status has already been marked as 'Completed' do not change it
    (item: any, countRepeatedTimes) => ({
      ...item,
      dh_status: countRepeatedTimes
        ? item.dh_status === 'Completed'
          ? item.dh_status
          : 'In Progress'
        : item.dh_status
    }),
    questionSectionOptional
  )
}

// STATES:
// 1. Question is reached: we need to add it to the data, with the 'question' case, without response status. Needed for when the user hits "repeat" and we haven't saved a response yet. Initial status "In progress"
// 2. Question is repeated with "repeat" button: 'updateQuestionRepeatCount'
// 3. Question is repeated with "back" button : 'question' case
// 2. Question is responded: 'response' case. Can take two states "Completed" or "Skipped"

const questionResponseReducer = (
  dischargeCarePlan: dischargeCarePlanType,
  config?: any
) => (state: any, action: any) => {
  switch (action.type) {
    case 'updateQuestionRepeatCount':
      return updateQuestionRepeatedTimes(
        action?.payload,
        state,
        dischargeCarePlan,
        false
      )
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'question':
          const { dischargeQuestionData } = state
          // If the first question has already been added, don't initialize it again the data
          // This step is needed for when we hit "back" button for the first question
          const hasInitialQuestion = dischargeQuestionData?.questionsAnswers?.find(
            qa => qa.questionId === 'question#24036'
          )

          // Just on the first question get initial data
          if (!hasInitialQuestion) {
            // on the initial question, we populate the QuestionData with all the sections
            return {
              ...state,
              dischargeQuestionData: getInitialDischargeQuestionData(
                dischargeCarePlan,
                action?.payload
              )
            }
          }

          return addQuestionResponse(action?.payload, state, dischargeCarePlan)
        case 'response':
          return updateQuestionResponse(
            action?.payload,
            state,
            dischargeCarePlan
          )
        default:
          return state
      }
    default:
      return state
  }
}

export default questionResponseReducer
